import React, { useState } from "react";
import axios from "axios";
import "./App.css"; // Importa il file CSS

function App() {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [trackId, setTrackId] = useState("");
  const [trackIdAll, setTrackIdAll] = useState(""); // Per aggiungere traccia a tutte le playlist
  const [selectedUserIds, setSelectedUserIds] = useState([]); // Modifica per supportare utenti multipli
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [includeLiked, setIncludeLiked] = useState(false);
  const [applyToAllUsers, setApplyToAllUsers] = useState(false);
  const [playlistFilter, setPlaylistFilter] = useState("all");

  const login = async () => {
    try {
      const response = await axios.get("https://spb.visionairmusic.com/admin/users", {
        headers: {
          username: username,
          password: password,
        },
      });
      setUsers(response.data);
      setLoggedIn(true);
    } catch (error) {
      console.error("Login failed:", error);
      setStatus("Invalid username or password.");
    }
  };

  const addTrack = async () => {
    console.log("Adding track", userId, playlistId, trackId); // Log per debug

    if (!trackId) {
      alert("Please enter a track ID!");
      return;
    }

    try {
      const response = await axios.post(
        "https://spb.visionairmusic.com/admin/add-track", // endpoint del backend
        {
          userId, // ID dell'utente
          playlistId, // ID della playlist
          trackId, // ID della traccia
        },
        {
          headers: {
            username: "admin", // autenticazione
            password: "admin", // autenticazione
          },
        }
      );
      console.log(response.data); // Stampa la risposta per debug
      setStatus("Track successfully added!"); // Mostra il messaggio di successo
    } catch (error) {
      console.error("Error adding track:", error); // Stampa l'errore
      setStatus(
        "Error adding track: " + (error.response?.data || error.message)
      );
    }
  };

  const addTrackToSelectedPlaylists = async () => {
    if (!trackIdAll) {
      alert("Per favore inserisci un track ID!");
      return;
    }

    try {
      const usersToProcess = applyToAllUsers
        ? users
        : users.filter((u) => selectedUserIds.includes(u.id));

      for (let user of usersToProcess) {
        let playlists = [];

        if (playlistFilter === "public") {
          playlists = user.playlists.public;
        } else if (playlistFilter === "private") {
          playlists = user.playlists.private;
        } else if (playlistFilter === "all") {
          playlists = [...user.playlists.public, ...user.playlists.private];
        } else if (playlistFilter === "specific") {
          playlists = user.playlists.public
            .concat(user.playlists.private)
            .filter((p) => selectedPlaylists.includes(p.id));
        }

        for (let playlist of playlists) {
          await axios.post(
            "https://spb.visionairmusic.com/admin/add-track",
            {
              userId: user.id,
              playlistId: playlist.id,
              trackId: trackIdAll,
            },
            {
              headers: {
                username: "admin",
                password: "admin",
              },
            }
          );
        }

        if (includeLiked) {
          await axios.post(
            "https://spb.visionairmusic.com/admin/add-liked-track",
            {
              userId: user.id,
              trackId: trackIdAll,
            },
            {
              headers: {
                username: "admin",
                password: "admin",
              },
            }
          );
        }
      }
      setStatus("Traccia aggiunta con successo!");
    } catch (error) {
      console.error("Errore nell'aggiunta della traccia:", error);
      setStatus("Errore nell'aggiunta della traccia.");
    }
  };

  return (
    <div className="app-container">
      {!loggedIn ? (
        <div className="login-form">
          <h1>Login Admin</h1>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={login}>Login</button>
          {status && <div className="status-message">{status}</div>}
        </div>
      ) : (
        <div className="admin-dashboard">
          <h1>Admin Dashboard</h1>

          <div className="track-form">
            {/* Track manuale playlist

            <h3>Add Track to Playlist</h3>
            <input
              type="text"
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Playlist ID"
              value={playlistId}
              onChange={(e) => setPlaylistId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Track ID"
              value={trackId}
              onChange={(e) => setTrackId(e.target.value)}
            />
            <button onClick={addTrack}>Add Track</button>
*/}
            <h3>Aggiungi brani nella libreria degli utenti</h3>
            <label>Inserisci l'ID della traccia:</label>
            <input
              type="text"
              placeholder="Inserire l'ID della traccia"
              value={trackIdAll}
              onChange={(e) => setTrackIdAll(e.target.value)}
            />

            {!applyToAllUsers && (
              <div>
                <label>Seleziona Utente:</label>
                <select
                  multiple
                  value={selectedUserIds}
                  onChange={(e) =>
                    setSelectedUserIds(
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                >
                  {users.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="option-inline">
              <label>
                <input
                  type="checkbox"
                  checked={applyToAllUsers}
                  onChange={(e) => setApplyToAllUsers(e.target.checked)}
                />
                Applica a tutti gli utenti
              </label>
            </div>

            <div>
              <label>Seleziona Playlists:</label>
              <select
                value={playlistFilter}
                onChange={(e) => setPlaylistFilter(e.target.value)}
              >
                <option value="all">Tutte le Playlist</option>
                <option value="public">Solo Pubbliche</option>
                <option value="private">Solo Private</option>
                <option value="specific">Seleziona Specifiche</option>
              </select>
            </div>

            {playlistFilter === "specific" && (
              <div>
                <label>Seleziona Playlist:</label>
                <select
                  multiple
                  value={selectedPlaylists}
                  onChange={(e) =>
                    setSelectedPlaylists(
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                >
                  {users
                    .filter(
                      (user) =>
                        applyToAllUsers || selectedUserIds.includes(user.id)
                    )
                    .flatMap((user) =>
                      [...user.playlists.public, ...user.playlists.private].map(
                        (playlist) => {
                          const tipo = user.playlists.public.includes(playlist)
                            ? "(PB)"
                            : "(PV)";
                          return (
                            <option value={playlist.id} key={playlist.id}>
                              {playlist.name} {tipo} - {user.name}
                            </option>
                          );
                        }
                      )
                    )}
                </select>
              </div>
            )}

            <div className="option-inline">
              <label>
                <input
                  type="checkbox"
                  checked={includeLiked}
                  onChange={(e) => setIncludeLiked(e.target.checked)}
                />
                Aggiungi ai brani preferiti
              </label>
            </div>
            <button onClick={addTrackToSelectedPlaylists}>
              Aggiungi il brano
            </button>
            {status && <div className="status-message">{status}</div>}
          </div>
          <h2>Utenti e Playlist</h2>
          {users.length === 0 ? (
            <p>Nessun utente disponibile.</p>
          ) : (
            <ul>
              {users.map((user) => (
                <li key={user.id}>
                  <h3>{user.name}</h3>
                  <p>Spotify ID: {user.id}</p>
                  <h4>Playlist Pubbliche</h4>
                  <ul>
                    {user.playlists.public.map((playlist) => (
                      <li key={playlist.id}>
                        {playlist.name} (ID: {playlist.id})
                      </li>
                    ))}
                  </ul>
                  <h4>Playlist Private</h4>
                  <ul>
                    {user.playlists.private.map((playlist) => (
                      <li key={playlist.id}>
                        {playlist.name} (ID: {playlist.id})
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
